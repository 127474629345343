<template>
  <v-dialog v-model="showLoginDialog" persistent :max-width="480">
    <!-- login-->
    <v-card v-if="currentTab === 'login'">
      <v-card-title class="headline">{{
        $t("login.notLoggedIn")
      }}</v-card-title>
      <v-card-text>
        <div>{{ $t("login.notLoggedInLong") }}</div>

        <v-divider class="my-3"></v-divider>
        <v-text-field
          v-model="email"
          :label="$t('label.email')"
          :rules="[rules.required, rules.email]"
        ></v-text-field>
        <v-text-field
          v-on:keyup.enter="triggerLoginOnEnter"
          v-model="password"
          :label="$t('label.password')"
          :rules="[rules.required, rules.min]"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>

        <v-alert v-if="loginError" type="error" outlined icon="mdi-alert">
          {{ $t("login.loginError") }}: "<em>{{ loginErrorText }}</em
          >"
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <div class="ml-4" :class="{ small: !loginError }">
          <v-icon color="warning" class="mr-2" v-if="loginError">
            mdi-chevron-right
          </v-icon>

          <i18n
            path="passwordReset.switch"
            tag="span"
            :class="loginError ? 'warning--text' : 'secondary--text'"
          >
            <template #reset_here>
              <a
                @click="currentTab = 'reset-password'"
                v-text="$t('passwordReset.link')"
              ></a>
            </template>
          </i18n>

          <v-icon color="warning" class="ml-2" v-if="loginError">
            mdi-chevron-left
          </v-icon>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-3"
          @click="doLogin"
          :disabled="!loginValid || requestInProgress"
          v-text="$t('label.login')"
        ></v-btn>
      </v-card-actions>
    </v-card>

    <!-- reset password -->
    <v-card v-else-if="currentTab == 'reset-password'">
      <v-card-title class="headline">{{
        $t("passwordReset.title")
      }}</v-card-title>
      <v-card-text>
        <div>{{ $t("passwordReset.text") }}</div>

        <v-text-field
          v-model="email"
          :label="$t('label.email')"
          :rules="[rules.required, rules.email]"
          class="mt-6"
        ></v-text-field>
        <v-alert v-if="resetError" type="error" outlined icon="mdi-alert">
          {{ $t("passwordReset.error") }}: "<em>{{ resetError }}</em
          >"
        </v-alert>
        <v-alert v-if="resetSuccess" type="success" outlined>
          <i18n path="passwordReset.success" tag="span" class="text--secondary">
            <template #reset_email>
              <a :href="'mailto:' + email" v-text="email"></a>
            </template>
          </i18n>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <div class="ml-4 small">
          <i18n
            path="passwordReset.backToLogin"
            tag="span"
            class="text--secondary"
          >
            <template #login>
              <a
                @click="
                  currentTab = 'login';
                  resetForm();
                "
                v-text="$t('label.login')"
              ></a>
            </template>
          </i18n>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-3"
          @click="doReset()"
          :disabled="!resetValid || requestInProgress"
          v-if="!resetSuccess"
          v-text="$t('passwordReset.button')"
        ></v-btn>
        <v-btn
          v-else
          @click="
            currentTab = 'login';
            resetForm();
          "
          v-text="$t('passwordReset.backToLoginFull')"
          class="mr-4 mb-3"
          color="primary"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import FormValidationMixin from "@/components/mixins/FormValidationMixin";

export default {
  name: "LoginDialog",

  mixins: [FormValidationMixin],

  props: {
    value: {},
  },

  data() {
    return {
      email: "",
      password: "",
      password2: "",
      currentTab:
        "reset-password" in this.$route.query ? "reset-password" : "login",
      resetError: null,
      showPassword: false,
      emailEdited: false, // when email gets edited, we hide associated error message
      passwordEdited: false,
      requestInProgress: false, // if a request was just sent to the backend and is processed
      resetSuccess: false, // reset email was sent
    };
  },
  computed: {
    ...mapState({
      loginError: (state) => state.login.loginError,
    }),
    ...mapGetters({
      loginErrorText: "loginErrorText",
      usesPasswordLogin: "usesPasswordLogin",
    }),
    showLoginDialog: {
      get() {
        return this.$store.state.login.showLoginDialog;
      },
      set(newValue) {
        this.$store.dispatch("setShowLoginDialog", { show: newValue });
      },
    },
    loginValid() {
      return (
        this.email !== "" &&
        this.rules.email(this.email) === true &&
        this.password.length >= 8
      );
    },
    resetValid() {
      return this.email !== "" && this.rules.email(this.email) === true;
    },
  },

  methods: {
    ...mapActions({
      login: "login",
      resetPassword: "resetPassword",
    }),
    resetForm() {
      this.resetError = null;
      this.resetSuccess = false;
      this.password = "";
      this.showPassword = false;
      this.passwordEdited = false;
      this.requestInProgress = false;
      this.$store.state.login.loginError = null;
    },
    async triggerLoginOnEnter() {
      if (this.loginValid) {
        await this.doLogin();
      }
    },
    async doLogin() {
      this.requestInProgress = true;
      try {
        await this.login({ email: this.email, password: this.password });
      } finally {
        this.requestInProgress = false;
      }
    },
    async doReset() {
      this.requestInProgress = true;
      this.resetError = null;
      this.resetSuccess = false;
      try {
        await this.resetPassword({ email: this.email });
        this.resetSuccess = true;
      } catch (error) {
        console.log(error);
        this.resetError = error;
      } finally {
        this.requestInProgress = false;
      }
    },
  },

  watch: {
    email() {
      this.emailEdited = true;
    },
    password() {
      this.passwordEdited = true;
    },
  },
};
</script>
<style lang="scss">
.v-select.v-text-field.short input {
  max-width: 0;
}

div.small {
  font-size: 80%;
}
</style>
